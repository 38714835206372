import React from "react";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

function CircularProgressWithLabel(props) {
	return (
		<Box style={{ position: 'relative', display: 'inline-flex'}}>
			<CircularProgress variant="determinate" {...props} thickness={2} style={{color: '#1976d2'}}/>
			<Box
				style={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Typography variant="caption" component="div" color="text.secondary">
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
	);
}

export default function CircularStatic({label = "Loading"}) {
	const [progress, setProgress] = React.useState(5);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 5));
		}, 300);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<Box component="div" display="grid" justifyContent="center" alignItems="center">
			<Typography variant="caption" component="div" style={{marginBottom: '10px', color: 'black'}}>
				{label}
			</Typography>
			<CircularProgressWithLabel value={progress} />
		</Box>
	);
}
